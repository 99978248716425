/* You can add global styles to this file, and also import other style files */
.main {
    position: absolute;
    top: var(--header-height);
    width: 100%;
}

.title-medium{
    font-size: 30px;
    font-weight: 700;
}

.title-small{
    font-size: 1.125rem;
    font-weight: 700;
}

:root {
    line-height: 1.5rem;
    --header-height: 4.5rem;
    --phone-width: 678px;
    --step-max-width: 50rem;
    --step-width: 100%;
    overflow-x: hidden;
    --request-button-height: 3.75rem;
    --request-button-font-size: 1rem;
    --mat-bottom-sheet-container-shape: 0;
    --mdc-outlined-text-field-container-shape: 1px;
    --mdc-outlined-text-field-outline-width: 1px;
}

body{
    margin: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

html, body { height: 100%; }
body {
    font-family: "Noto Sans", sans-serif;
    margin: 0;

}

p, li{
    font-size: 1rem;
}

@media (max-width: 678px){
    :root{
        font-size: 1rem;
    }
}
app-step{
    flex:1;
    background-color: #F6F6F6;
}


